<template>
  <div 
    class="item-line-wrapper" 
    v-b-tooltip.hover :title="parseItemType(item?.item?.type) + ' | '+ item.name"
  >
    <Procedure v-if="parseItemType(item?.item?.type) === 'Procedimento'" class="icon2" />
    <Telemedicine v-else-if="parseItemType(item?.item?.type) === 'Telemedicina'" class="icon" />
    <Appointment v-else-if="parseItemType(item?.item?.type) === 'Presencial'" class="icon2" />
    <Return v-else-if="parseItemType(item?.item?.type) === 'Retorno'" class="icon" />
    <Exam v-else-if="parseItemType(item?.item?.type) === 'Exame'" class="icon3" />
    <Surgical v-else-if="parseItemType(item?.item?.type) === 'Centro cirúrgico'" class="icon surgical mr-2" />
    <Product v-else-if="parseItemType(item?.item?.type) === 'Produto'" class="icon" />

    <div class="type" :class="{ 'surgical': parseItemType(item?.item?.type) === 'Centro cirúrgico' }">{{ parseItemType(item?.item?.type) }}</div>
    <div class="pipe" />
    <div class="name-wrapper">
      <div class="name-ellipsis">
        {{ item.name ?? item.item.name }}
      </div>
    </div>
    <!-- <div class="name">{{ item.name }}</div> -->
    <!-- <Icon :tooltip="item.name"> -->
    <!-- </Icon> -->
  </div>
</template>
<script>
import { parseItemType } from '@/utils/itemHelper'
// import Icon from '@/components/General/Icon'
import Procedure from '@/assets/icons/procedure.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'
import Appointment from '@/assets/icons/appointment.svg'
import Return from '@/assets/icons/return.svg'
import Exam from '@/assets/icons/exam.svg'
import Product from '@/assets/icons/product.svg'
import Surgical from '@/assets/icons/surgical.svg'

export default {
  props: {
    item: Object,
  },
  components: {
    // Icon,
    Procedure,
    Telemedicine,
    Appointment,
    Return,
    Exam,
    Product,
    Surgical
  },
  methods: {
    parseItemType,
  }
}
</script>
<style lang="scss" scoped>
.item-line-wrapper {
  width: auto;
  display: flex;
  align-items: center;
  gap: 5px;

    &>div{
      display: inline-block;
    }
}
.icon {
  width: 20px;
  min-width: 20px;
  fill: var(--blue-500);

  &.surgical {
    width: 2vw;
    path {
      fill: var(--blue-500);
    }
  }
}
.icon2 {
  width: 20px;
  min-width: 20px;
  fill: var(--blue-500);
}
.icon3 {
  width: 20px;
  min-width: 20px;
  fill: var(--blue-500);
}
.type {
  color: var(--blue-500);

  &.surgical {
    width: 10vw !important;
  }
}
.pipe {
  width: 1px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--blue-500);
}
.name-wrapper {
  position: relative;
  width: 100%;
  .name-ellipsis {
    position: absolute;
    top: -10px;
    left: 0;
    right: 20px;
    color: var(--type-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>