

export const parseType = (item) => {
  if (item?.item?.type) {
    return parseProcedureType(item.item.type)
  }

  // TODO remove after procedures refactory
  switch(item.item_type) {
    case 'App\\Models\\TissGuide\\TissTableProcedure':
    case 'App\\Models\\ClinicProcedure':
      return parseProcedureType(item?.item?.type)
    case 'App\\Models\\TissGuide\\Item\\TissGuideItem':
      return parseType(item.item)
    case 'App\\Models\\Warehouse\\Product':
    case 'App\\Models\\TUSS\\TussMaterial':
      return 'Produto';
    case 'App\\Models\\Procedure':
      if(item?.item?.tiss_table_procedure) return parseProcedureType(item.item.tiss_table_procedure.type)
      return 'Procedimento'
    case 'App\\Models\\TissGuide\\Import\\TissImportedExpense':
      return 'Despesa'
    default:
      return item.item_type;
  }
}

export const parseItemType = (type) => {
  switch(type) {
    case 'PROCEDURE':
      return 'Procedimento';
    case 'APPOINTMENT':
      return 'Agendamento';
    case 'EXAM':
      return 'Exame';
    case 'SURGICAL':
      return 'Centro cirúrgico';
    case 'RETURN':
      return 'Retorno';
    case 'TELEMEDICINE':
      return 'Telemedicina';
    case 'OTHER_EXPENSES':
      return 'Despesa';
    case 'PRODUCT':
      return 'Produto';
    default :
      return type;
    }
}

const parseProcedureType = (subType) => {
  switch(subType) {
    case 'PROCEDURE':
      return 'Procedimento';
    case 'TELEMEDICINE':
      return 'Telemedicina';
    case 'APPOINTMENT':
      return 'Presencial';
    case 'RETURN':
      return 'Retorno';
    case 'EXAM':
      return 'Exame';
    case 'SURGICAL':
      return 'Centro cirúrgico';
    case 'OTHER_EXPENSES':
        return 'Despesa';
    default:
      return subType;
  }
}
